<template>
  <div>
    <div class="title flex-between">
      <div>{{ $t("titleI18n.Deposit") }}</div>
      <div>&nbsp;</div>
    </div>
    <el-col :span="24">
      <div class="flex-base">
        <el-image :src="imgSrc[0]" style="width: 18px; height: 18px; margin: 0 5px;"></el-image>
        <el-link type="primary" :underline="false">{{ $t("titleI18n.RechargeInstructions") }}</el-link>
      </div>
      <div class="tips-bg">
        <div>{{$t("tipsI18n.Recharge1")}}</div><br/>
        <div>{{$t("tipsI18n.Recharge2")}}</div><br/>
        <div><span :class="language === 'zh-en' ? 'en-width' : 'zh-width'">{{$t("tipsI18n.RechargeBankN")}}</span>{{$t("tipsI18n.RechargeBankV")}}</div>
        <div><span :class="language === 'zh-en' ? 'en-width' : 'zh-width'">{{$t("tipsI18n.RechargePlatN")}}</span>{{$t("tipsI18n.RechargePlatV")}}</div>
        <div><span :class="language === 'zh-en' ? 'en-width' : 'zh-width'">{{$t("tipsI18n.RechargeNumberHKDN")}}</span>{{$t("tipsI18n.RechargeNumberHKDV")}}</div>
        <div><span :class="language === 'zh-en' ? 'en-width' : 'zh-width'">{{$t("tipsI18n.RechargeNumberUSDN")}}</span>{{$t("tipsI18n.RechargeNumberUSDV")}}</div>
        <div><span :class="language === 'zh-en' ? 'en-width' : 'zh-width'">{{$t("tipsI18n.RechargeSCN")}}</span>{{$t("tipsI18n.RechargeSCV")}}</div><br/>
        <div>{{$t("tipsI18n.Recharge3")}}</div><br/>
        <div>{{$t("tipsI18n.Recharge31")}}</div><br/>
        <div>{{$t("tipsI18n.Recharge32")}}</div>
      </div>
    </el-col>
    <el-col :span="24">
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="195px"
        class="demo-ruleForm"
      >
        <el-form-item :label="$t('formTitleI18n.RechargeAmount')" prop="moneyFormat">
          <el-input v-model="ruleForm.moneyFormat" @change="formatAmount" style="width: 300px;"></el-input>
          {{ $t("unitI18n.Dollars") }}
          <div class="tips-color">{{ $t('tipsI18n.DescRecharge') }}</div>
        </el-form-item>
<!--        <el-form-item :label="$t('formTitleI18n.TransactionCode')" prop="serialNumber">-->
<!--          {{ruleForm.serialNumber}}-->
<!--        </el-form-item>-->
        <el-form-item :label="$t('formTitleI18n.BankName')" prop="receivingBank">
          <label>{{ ruleForm.receivingBank }}</label>
        </el-form-item>
        <el-form-item :label="$t('companyCreate.bankNumber')" prop="bankAccountNumber">
          <label>{{ ruleForm.bankAccountNumber }}</label>
        </el-form-item>
        <el-form-item :label="$t('companyCreate.bankAccount')" prop="accountHolderName">
          <label>{{ ruleForm.accountHolderName }}</label>
        </el-form-item>
        <el-form-item :label="$t('companyCreate.swiftCode')" prop="swiftCode">
          <label>{{ ruleForm.swiftCode }}</label>
        </el-form-item>
      </el-form>
    </el-col>
    <el-col :span="24" class="flex-row-center">
      <el-button
        class="btn-black"
        @click="submitRecharge"
        :disabled="submitForm"
      >{{ $t("btnI18n.Submit") }}</el-button>
      <el-button
        class="btn-black"
        @click="$router.go(-1);"
        :disabled="submitForm"
      >{{ $t("btnI18n.Cancel") }}</el-button>
    </el-col>
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :title="$t('dialogMsg.transferCode')"
        :visible.sync="dialogVisRecharge"
        width="35%"
        style="min-width: 350px;"
        @close="close()">
        <div>
          <div style="text-align: center; color: #000000; padding: 20px 0">
            <span style="font-size: 24px; font-weight: 800;">{{ $t("dialogMsg.transferCode") }}: </span>
            <span
              style="font-size: 24px; font-weight: 800; letter-spacing: 10px"
              id="supplier-number"
          >{{transferCode}}</span>
            <span
                style="font-size: 14px; padding-left: 20px; color: #81d8d0; cursor: pointer; position: absolute; line-height: 35px;"
                class="copyBtn"
                data-clipboard-target="#supplier-number"
                @click="copyClick()"
            >{{ $t('copyText') }}</span>
          </div>
          <div v-if="language === 'zh-cn'">{{ $t('tipsMessage.transferCodeDesc') }}</div>
          <div v-else style="text-align: center">
            {{ $t('tipsMessage.transferCodeDesc').split(',')[0] + ', ' }}
            <br />
            {{ $t('tipsMessage.transferCodeDesc').split(',')[1] }}
          </div>
        </div>
        <div slot="footer">
          <div style="display: flex; justify-content: center">
            <el-button @click="close()">{{ $t('btnI18n.Close') }}</el-button>
          </div>
        </div>
      </el-dialog>
  </div>
</template>

<script>
import filter from "@/filters";
import Clipboard from "clipboard";

export default {
  name: "recharge",
  data() {
    return {
      imgSrc: [require("@/static/img/tips-recharge.png")],
      submitForm: false,
      dialogVisRecharge: false,
      backUrl: "",
      requestUrl: "",
      userUrl: "",
      totalAmount: 0,
      ruleForm: {
        bankAccountNumber: "",
        receivingBank: "",
        accountHolderName: "",
        moneyFormat: 0,
        tokenVal: 0,
        // serialNumber: "",
        swiftCode: "",
        txId: ""
      },
      rules: {
        accountHolderName: [{ required: true, message: this.$t('userCannotEmpty'), trigger: 'blur' }],
        bankAccountNumber: [{ required: true, message: this.$t('accountCannotEmpty'), trigger: 'blur' }],
        receivingBank: [{ required: true, message: this.$t('bankCannotEmpty'), trigger: 'blur' }],
        moneyFormat: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        tokenVal: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        // serialNumber: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        swiftCode: [{ required: true, message: this.$t('companyCreate.swiftCodeCannotEmpty'), trigger: 'blur' }]
      },
      transferCode: "",
    };
  },
  mounted() {
    this.backUrl = this.$route.path.includes("supply") ? '/supply-tr' : '/invest-tr';
    this.getUserInfo();
  },
  methods: {
    formatAmount() {
      /^\d+$/.test(this.ruleForm.moneyFormat) ? this.ruleForm.moneyFormat > 0 ? this.ruleForm.moneyFormat : this.ruleForm.moneyFormat = 1 : this.ruleForm.moneyFormat = 1;
      this.ruleForm.moneyFormat = filter.numberFormat(this.ruleForm.moneyFormat, 0);
      this.ruleForm.tokenVal = filter.reNumber(this.ruleForm.moneyFormat);
    },
    getUserInfo() {
      this.$axios.get('/v1/user/bank-card-info').then(res => {
        if (res.code === 0 || res.code === '0') {
          this.ruleForm.bankAccountNumber = res.data.bankAccountNumber;
          this.ruleForm.receivingBank = res.data.receivingBank;
          this.ruleForm.accountHolderName = res.data.accountHolderName;
          this.ruleForm.swiftCode = res.data.swiftCode;
        } else {
          this.$message({ type: 'error', message: res.message });
        }
      });
    },
    copyClick() {
      let clipboard = new Clipboard('.copyBtn');
      clipboard.on('success', (e) => {
        this.$message({
          message: '复制成功',
          type: 'success'
        });
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on('error', (e) => {
        this.$message({
          message: '复制失败',
          type: 'error'
        });
        // 释放内存
        clipboard.destroy();
      });

    },
    close() {
      this.$router.push({ path: '/invest-tr/record-logs' });
    },
    submitRecharge() {
      if (!this.ruleForm.moneyFormat || this.ruleForm.moneyFormat === "" || Number(this.ruleForm.moneyFormat) <= 0) {
        this.$message.error(this.$t('validateFormTips.requireAmount'));
        return false;
      }
      // if (!this.ruleForm.serialNumber || this.ruleForm.serialNumber === "") {
      //   this.$message.error(this.$t('validateFormTips.requireTransactionCode'));
      //   return false;
      // }
      this.submitForm = true;
      const data = {
        tokenVal: (this.ruleForm.tokenVal).toString(),
        // transactionCode: this.ruleForm.serialNumber
      };
      this.$axios.post("/v1/user/recharge", { ...data }).then(result => {
        if (result.code === 0 || result.code === '0') {
          this.submitForm = false;
          this.transferCode = result.data.transferCode;
          this.dialogVisRecharge = true;
        } else {
          this.submitForm = false;
          this.$message({ type: 'error', message: result.message });
        }
      }).catch((error) => {
        console.log("submit form error", error);
        this.submitForm = false;
      });
    }
  },
  computed: {
    language() {
      return this.$store.getters['auth/language'];
    }
  },
  watch: {
    language() {}
  }
};
</script>

<style scoped lang="scss">
.tips-color {
  color: #7e7e7e;
}
.tips-bg {
  background-color: #F4F4F4;
  padding: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
  span {
    display: inline-block;
    text-align: right;
    margin-right: 1rem;
  }
  .zh-width {
    width: 10rem;
  }
  .en-width {
    width: 16rem;
  }
}
</style>
